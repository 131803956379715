.icon__seo {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconSeoAppear 1s 1s forwards;
  }
  &:hover {
    .icon__seo__text {
      transform-origin: center center;
      animation: iconSeoAnim 0.5s ease-in-out infinite;
    }
  }
}

@keyframes iconSeoAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconSeoAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
