.wrapper {
  width: 50vw;

  @media only screen and (max-width: 650px) {
    width: 80vw;
  }
  @media only screen and (max-width: 500px) {
    width: 85vw;
  }
}

.calculationResult {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__block {
    width: 100%;
    margin-bottom: 8rem;
  }

  &__heading {
    margin-bottom: 1rem;

    text-align: center;
  }

  &__headingCta {
    margin-top: -3rem;
    margin-bottom: 2rem;
  }

  &__cta {
    width: 100% !important;

    transition: 0.2s all ease-in;

    &_invisible {
      opacity: 0;
    }
  }
}
