@import '../../../main';

.calculationQuestionare {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.wrapper {
  width: 50rem;

  @media only screen and (max-width: 650px) {
    width: 80vw;
  }
  @media only screen and (max-width: 500px) {
    width: 85vw;
  }
}

.answersWrapper {
  margin: 3rem 4rem;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    margin: 3rem 2rem;
  }
}

.question {
  width: 100%;

  text-align: left;
}

.answer {
  margin-bottom: 1.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    .answer__input__filler {
      background-color: $color-secondary-dark;
    }
  }

  &__input {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;
    border: 2px solid $color-secondary-dark;
    background-color: $color-secondary-darker;

    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 500px) {
      width: 3.5rem;
      height: 3.5rem;
    }

    &__filler {
      width: 85%;
      height: 85%;
      border-radius: 50%;
      background-color: transparent;

      transition: 0.2s all;

      &_active {
        background-color: $color-secondary-dark;
      }
    }
  }
}

.cta {
  width: 100%;
}

.progressBar {
  width: 100%;
  height: 3rem;
  margin-top: 3rem;
  border-radius: 0.5rem;
  background-color: $color-secondary-darker;
  border: 3px solid $color-secondary-darker;
  overflow: hidden;

  display: flex;
  align-items: center;

  &__filler {
    width: 50%;
    height: 100%;
    background-color: $color-secondary;
    border-radius: 0.3rem;

    transition: 0.5s all;
  }
}
