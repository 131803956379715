@import '../../main';

.home {
  overflow: hidden;

  &__header {
    height: calc(100vh - 11rem);

    position: relative;

    @media screen and (max-width: 650px) {
      height: calc(90vh - 5rem);
    }
    &__gradient {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0.2;
    }
  }
  &__square {
    height: 30rem;
    width: 30rem;

    z-index: -1;
    position: absolute;

    &--1 {
      left: 30rem;

      @media screen and (max-width: 500px) {
        left: 0;
      }
    }
    &--2 {
      right: 15rem;
      bottom: 5rem;

      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
  &__heading {
    width: 100%;

    text-align: center;

    display: none;

    transform: translate(-50%, -50%);

    position: absolute;
    top: 40%;
    left: 50%;

    @media screen and (max-width: 450px) {
      display: block;

      bottom: 16rem;
      top: unset;
      letter-spacing: 3px;
    }

    & .heading-1 {
      opacity: 0;
      text-shadow: 1px 1px 1px rgba($color-secondary, 0.4);

      @keyframes homeHeading {
        0% {
          opacity: 0;
          transform: translateY(10px);
        }
        100% {
          opacity: 0.75;
          transform: translateY(0);
        }
      }

      animation: homeHeading 1s 3s forwards;

      @media screen and (max-width: 450px) {
        margin: 0;
        padding-left: 1.7rem;
        padding-right: 1.7rem;

        font-size: 4.4rem;
        line-height: 1.6;
      }
      @media screen and (max-width: 400px) {
        font-size: 3.9rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 3.5rem;
        line-height: 1.6;
      }
    }
  }
  &__links {
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateX(-50%);
    position: absolute;
    bottom: 6rem;
    left: 50%;

    @media screen and (max-width: 1200px) {
      bottom: 4rem;
    }

    & .cta {
      letter-spacing: 3px;
      height: 11rem;
      font-size: 2.3rem;
      width: 40rem;
      border: 0;

      @keyframes ctaHome {
        0% {
          opacity: 0;
          transform: rotateX(200deg) !important;
        }
        100% {
          opacity: 1;
          transform: rotateX(0deg) !important;
        }
      }

      opacity: 0;

      animation: ctaHome 1s 6s forwards;

      @media screen and (max-width: 450px) {
        animation: ctaHome 1s 4s forwards;
      }

      @media screen and (max-width: 900px) {
        width: 30rem;
        height: 10rem;
        font-size: 2.1rem;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.8rem;
        height: 8rem;
      }
      @media screen and (max-width: 400px) {
        width: 25rem;
      }
    }
  }
  &__rocket {
    height: 90rem;
    overflow: hidden;
    background-color: #131616;

    position: relative;
    z-index: 2;

    @media only screen and (max-width: 1250px) {
      height: 70rem;
    }
    @media only screen and (max-width: 450px) {
      height: 50rem;
    }

    &--animate {
      & .home__rocket__img {
        display: block;

        @keyframes homeRocketAnimDefault {
          from {
            opacity: 0;
            transform: translate(-100vw, 50%) rotate(40deg);
          }
          to {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(0);
          }
        }
        @keyframes homeRocketAnim1700 {
          from {
            transform: translate(-100vw, 50%) rotate(40deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(-20deg);
          }
        }
        @keyframes homeRocketAnim1100 {
          from {
            transform: translate(-300vw, 50%) rotate(40deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(-40deg);
          }
        }

        animation: homeRocketAnimDefault 5s cubic-bezier(0.08, 0.99, 0.19, 1.06) forwards;

        @media screen and (max-width: 1700px) {
          animation: homeRocketAnim1700 5s cubic-bezier(0.08, 0.99, 0.19, 1.06) forwards;
        }
        @media screen and (max-width: 1100px) {
          animation: homeRocketAnim1100 5s cubic-bezier(0.08, 0.99, 0.19, 1.06) forwards;
        }
      }

      & .home__rocket__point {
        display: flex;

        animation: homeRocketPointAnim 0.2s 2.8s ease-out forwards;
      }
    }

    &__img {
      height: 75%;

      display: none;

      transform: translate(-100vw, 50%) rotate(40deg);
      position: absolute;
      left: 50%;
      top: 50%;

      @media only screen and (max-width: 1700px) {
        height: 65%;

        transform: translate(-50%, -50%) rotate(-20deg);
      }
      @media only screen and (max-width: 1250px) {
        top: 55%;
      }
      @media only screen and (max-width: 1100px) {
        transform: translate(-300vw, 50%) rotate(40deg);
      }
      @media only screen and (max-width: 800px) {
        height: 50%;
      }
      @media only screen and (max-width: 600px) {
        left: 70%;
      }
      @media only screen and (max-width: 450px) {
        top: 65%;
      }
    }
    &__point {
      width: 40rem;
      padding: 1rem;

      color: transparent;

      display: none;
      align-items: center;
      justify-content: center;

      position: absolute;

      @keyframes homeRocketPointAnim {
        from {
          color: transparent;
        }
        to {
          color: $color-white;
        }
      }

      @media only screen and (max-width: 1700px) {
        width: 30rem;
      }
      @media only screen and (max-width: 1100px) {
        width: 25rem;
      }
      @media only screen and (max-width: 600px) {
        font-size: 1.4rem;
      }

      &--1 {
        padding-right: 10rem;

        left: 30%;
        top: 25%;

        @media only screen and (max-width: 1700px) {
          left: 25%;
        }
        @media only screen and (max-width: 1100px) {
          left: 15%;
          padding-right: 4rem;
        }
        @media only screen and (max-width: 800px) {
          left: 10%;
        }
        @media only screen and (max-width: 450px) {
          left: -2%;
          top: 35%;
        }

        &::after {
          transform: translate(-100%, 50%);
          left: 0;

          @media only screen and (max-width: 800px) {
            left: 50%;
          }
        }
        &::before {
          @media only screen and (max-width: 800px) {
            left: 50%;
          }
        }
      }
      &--2 {
        padding-right: 10rem;

        left: 14%;
        top: 77%;

        @media only screen and (max-width: 1700px) {
          top: 83%;
        }
        @media only screen and (max-width: 1100px) {
          left: 9%;
          padding-right: 4rem;
        }
        @media only screen and (max-width: 800px) {
          top: 70%;
          left: 7%;
        }
        @media only screen and (max-width: 600px) {
          left: 7%;
          top: 35%;
        }
        @media only screen and (max-width: 450px) {
          left: -5%;
          top: 45%;
        }

        &::after {
          transform: translate(-100%, 50%);
          left: 0;

          @media only screen and (max-width: 800px) {
            left: 50%;
          }
        }
        &::before {
          @media only screen and (max-width: 800px) {
            left: 50%;
          }
        }
      }
      &--3 {
        padding-left: 10rem;

        left: 55%;
        top: 60%;

        @media only screen and (max-width: 1100px) {
          left: 59%;
          padding-left: 4rem;
        }
        @media only screen and (max-width: 800px) {
          left: 62%;
        }
        @media only screen and (max-width: 600px) {
          left: 4%;
          top: 45%;
        }
        @media only screen and (max-width: 450px) {
          left: -8%;
          top: 55%;
        }

        &::after {
          transform: translate(100%, 50%);
          right: 0;

          @media only screen and (max-width: 800px) {
            transform: translate(0, 50%);
            right: 50%;
          }
        }
        &::before {
          @media only screen and (max-width: 800px) {
            right: 50%;
          }
        }
      }
      &--4 {
        padding-left: 10rem;

        left: 70%;
        top: 20%;

        @media only screen and (max-width: 1700px) {
          left: 67%;
        }
        @media only screen and (max-width: 1100px) {
          left: 62%;
          padding-left: 4rem;
        }
        @media only screen and (max-width: 800px) {
          left: 56%;
        }
        @media only screen and (max-width: 600px) {
          left: 1%;
          top: 55%;
        }
        @media only screen and (max-width: 450px) {
          left: -11%;
          top: 65%;
        }

        &::after {
          transform: translate(100%, 50%);
          right: 0;

          @media only screen and (max-width: 800px) {
            transform: translate(0, 50%);
            right: 50%;
          }
        }
        &::before {
          @media only screen and (max-width: 800px) {
            right: 50%;
          }
        }
      }

      &::after,
      &::before {
        content: '';
        display: block;
      }
      &::after {
        height: 0;
        width: 0;
        border-radius: 50%;
        border: 1px solid $color-secondary;
        opacity: 0;

        position: absolute;
        bottom: 0;

        animation: homeRocketPointAfterAnimDefault 0.2s 2.8s ease-out forwards;

        @keyframes homeRocketPointAfterAnimDefault {
          from {
            height: 0;
            width: 0;
            opacity: 0;
            transform: scaleY(0);
          }
          to {
            width: 5rem;
            height: 5rem;
            opacity: 1;
          }
        }
        @keyframes homeRocketPointAfterAnim1250 {
          from {
            height: 0;
            width: 0;
            opacity: 0;
            transform: scaleY(0);
          }
          to {
            width: 3rem;
            height: 3rem;
            opacity: 1;
          }
        }
        @keyframes homeRocketPointAfterAnim800 {
          from {
            height: 0;
            width: 0;
            opacity: 0;
            transform: scaleY(0);
          }
          to {
            width: 1.5rem;
            height: 1.5rem;
            opacity: 1;
          }
        }

        @media only screen and (max-width: 1250px) {
          height: 3rem;
          width: 3rem;

          animation: homeRocketPointAfterAnim1250 0.2s 2.8s ease-out forwards;
        }
        @media only screen and (max-width: 800px) {
          height: 1.5rem;
          width: 1.5rem;

          bottom: -0.5rem;
          left: 50%;

          animation: homeRocketPointAfterAnim800 0.2s 2.8s ease-out forwards;
        }
        @media only screen and (max-width: 600px) {
          transform: translate(-100%, 50%);
          left: 50%;
        }
      }
      &::before {
        width: 100%;
        height: 1px;
        background-color: $color-secondary;
        transform: scaleX(0);

        position: absolute;
        bottom: 0;

        animation: homeRocketPointBeforeAnim 0.5s 2.5s ease-out forwards;

        @keyframes homeRocketPointBeforeAnim {
          from {
            transform: scaleX(0);
          }
          to {
            transform: scaleX(1);
          }
        }

        @media only screen and (min-width: 801px) {
          left: 0;
        }
        @media only screen and (max-width: 800px) {
          width: 50%;

          bottom: -0.5rem;
        }
        @media only screen and (max-width: 600px) {
          left: 50%;
        }
      }
    }
  }
  &__reviews-section {
    padding-bottom: 0rem !important;
  }
  &__reviews {
    &__heading-2 {
      margin-left: 10rem;

      @media only screen and (max-width: 700px) {
        margin-left: 5rem;
      }
      @media only screen and (max-width: 400px) {
        margin-left: 2rem;
      }
    }
    &__cta {
      margin-top: 2rem;
      margin-bottom: 30rem;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#planet {
  position: absolute;
  top: -11rem;
  width: 100vw;
  left: 0;
  height: 100vh;
}
