@import '../../main';

.cta {
  width: 40rem;
  height: 8rem;
  border-radius: 0.5rem;
  cursor: pointer;

  font-size: 2.5rem;
  text-align: center;
  color: $color-secondary-light;
  text-shadow: 1px 1px 3px $color-black;
  background-color: $color-action;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  position: relative;

  transition: all 0.2s;

  @media only screen and (max-width: 450px) {
    width: 30rem;
  }

  &:hover {
    transform: translateY(6px) !important;

    & .cta__canvas {
      transform: translateY(0);
    }

    &::after {
      transform: translateY(100%);
    }
  }

  &::after {
    content: '';

    width: 100%;
    height: 100%;
    background-color: $color-action-light;
    border-radius: 2.5rem;

    display: block;

    position: absolute;
    z-index: 120;

    transform: translateY(-100%);
    transition: transform 0.5s;
  }

  &_main {
    width: 60rem;
    background-image: linear-gradient(
      to bottom,
      rgba($color-action, 0.2) 20%,
      $color-action 40% 60%,
      rgba($color-action, 0.2)
    );
    background-size: 100% 500%;
    background-position: 50% 50%;

    overflow: hidden;

    @media only screen and (max-width: 1000px) {
      width: 40rem;
    }
    @media only screen and (max-width: 800px) {
      width: 35rem;
    }
    @media only screen and (max-width: 450px) {
      width: 30rem;
    }
  }
  &_primary {
    background-image: linear-gradient(
      to bottom,
      rgba($color-action, 0.2) 20%,
      $color-action 40% 60%,
      rgba($color-action, 0.2)
    );
    background-size: 100% 500%;
    background-position: 50% 50%;
  }

  & p {
    position: relative;
    z-index: 11;
  }

  &__inner {
    width: 100%;
  }

  &__canvas {
    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 10;

    transform: translateY(100%);
    overflow: hidden;

    transition: transform 1s;
  }

  @media only screen and (max-width: 500px) {
    padding: 4rem 8rem;
  }
  @media only screen and (max-width: 400px) {
    padding: 3rem 5rem;

    font-size: 2rem;
  }
  @media only screen and (max-width: 350px) {
    padding: 3rem 5rem;
  }
}
