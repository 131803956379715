.icon__eshop {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconEshopAppear 1s forwards;
  }
  &:hover {
    .icon__eshop__dollar {
      transform-origin: center center;
      animation: iconEshopAnim 1s ease-in-out infinite;
    }
  }
}

@keyframes iconEshopAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconEshopAnim {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(1rem);
    opacity: 0;
  }
  50% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
