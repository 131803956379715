.icon__ga {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconGaAppear 1s 0.5s forwards;
  }

  &__small,
  &__large,
  &__regular {
    transform: scaleY(0.9);
    transform-origin: center bottom;
  }

  &:hover {
    .icon__ga {
      &__small,
      &__large,
      &__regular {
        animation: iconGaAnim 1.5s ease-in-out infinite;
      }
      &__small {
        animation-delay: 0.1s;
      }
      &__large {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes iconGaAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconGaAnim {
  0% {
    transform: scaleY(0.9);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0);
  }
  60% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.9);
  }
}
