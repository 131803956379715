@import '../../main';

.contact {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  position: relative;

  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__square {
    position: absolute;
    width: 30rem;
    height: 30rem;

    &--1 {
      top: 10rem;
      left: 0;

      @media screen and (max-width: 900px) {
        left: -15rem;
      }
    }
    &--2 {
      right: 0;
      top: 5rem;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    &--3 {
      top: 40rem;
      right: 20rem;

      @media screen and (max-width: 1200px) {
        bottom: 0;
        top: unset;
      }
      @media screen and (max-width: 900px) {
        bottom: 5rem;
        right: -10rem;
      }
    }
  }
  &__our {
    width: 20vw;
    padding: 2rem 5rem;
    transform: translateX(5rem);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    z-index: 200;
    position: absolute;
    right: 5rem;
    top: 3rem;

    border: 1px solid $color-secondary;
    border-radius: 8px;

    @media only screen and (max-width: 2000px) {
      transform: translateX(6rem);
    }
    @media only screen and (max-width: 1800px) {
      width: 25vw;
    }
    @media only screen and (max-width: 1400px) {
      width: 30vw;
    }
    @media only screen and (max-width: 1200px) {
      padding: 2rem 4rem;
    }
    @media only screen and (max-width: 1100px) {
      width: 35vw;
      top: 5rem;
    }

    @media only screen and (max-width: 900px) {
      width: 50vw;
      margin-top: 6rem;

      transform: translateX(50%);

      position: inherit;
      right: 0;
      top: 0;
    }
    @media only screen and (max-width: 700px) {
      width: 60vw;

      transform: translateX(34%);
    }
    @media only screen and (max-width: 900px) {
      width: 70vw;

      transform: translateX(22%);
    }

    @media only screen and (max-width: 500px) {
      border: none;
      justify-content: center;
    }

    @media only screen and (max-width: 450px) {
      top: 3rem;
    }

    &__heads {
      color: white;

      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 500px) {
        display: none;
      }

      &__header {
        margin-bottom: 1rem;
      }
    }
    &__texts {
      color: white;
      text-decoration: underline;

      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 500px) {
        align-items: center;
      }

      &__text {
        margin-bottom: 1rem;

        color: inherit;
      }
    }
  }

  &__content {
    width: 100vw;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 100;

    @media only screen and (max-width: 900px) {
      margin-top: 6rem;
    }

    & .heading-1 {
      margin-bottom: 20rem;
      color: $color-secondary;
      @media only screen and (max-width: 1200px) {
        margin-bottom: 17rem;
      }
      @media only screen and (max-width: 1000px) {
        margin-bottom: 14rem;
      }

      @media only screen and (max-width: 900px) {
        position: absolute;
        top: -55%;
      }

      @media only screen and (max-width: 800px) {
        top: -50%;
      }

      @media only screen and (max-width: 450px) {
        top: -40%;
      }
    }

    &__background {
      width: 70vw;
      transform: scaleY(70%);

      padding: 0 5rem;

      position: absolute;

      top: 8rem;

      z-index: -1;

      @media only screen and (max-width: 2000px) {
        width: 80vw;
        transform: scaleY(80%);
      }
      @media only screen and (max-width: 1800px) {
        transform: scaleY(90%);
        top: 13rem;
      }
      @media only screen and (max-width: 1600px) {
        top: 15rem;
      }
      @media only screen and (max-width: 1500px) {
        transform: scaleY(100%);
        width: 85vw;
      }
      @media only screen and (max-width: 1400px) {
        top: 17rem;
      }
      @media only screen and (max-width: 1300px) {
        transform: scaleY(110%);
        top: 20rem;
      }
      @media only screen and (max-width: 1050px) {
        transform: scaleY(130%);
      }
      @media only screen and (max-width: 900px) {
        top: 0;
      }
    }
    &__form {
      z-index: 100;
    }
    & .cta {
      margin-bottom: 2rem;

      @media only screen and (max-width: 500px) {
        width: 70vw;
      }
    }
    &__secure {
      text-decoration: none;
      color: white;

      margin-top: 1rem;
      margin-bottom: 5rem;
    }
  }
}
