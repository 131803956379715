.icon__branding {
  transform-origin: center bottom;

  &:hover {
    & .icon__branding__eye {
      animation: iconBrandingAppear 5s linear infinite;
    }
    & .icon__branding__eye__pupil {
      animation: iconBrandingPupil 2.5s linear infinite;
    }
  }
  &__eye {
    opacity: 1;

    &__pupil {
      transform-origin: calc(50% + 4px) 90px;
      transform: scale(0.5);
    }
  }
}

@keyframes iconBrandingAppear {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.25;
  }
  20% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
}
@keyframes iconBrandingPupil {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
