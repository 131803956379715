.icon__90 {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: icon90Appear 1s forwards;
  }
  &:hover {
    .icon__90__number {
      transform-origin: center center;
      animation: icon90NumberAnim 0.5s ease-in-out infinite;
    }
    .icon__90__perc {
      transform-origin: center center;
      animation: icon90PercAnim 0.5s ease-in-out infinite;
    }
  }
}

@keyframes icon90Appear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes icon90NumberAnim {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes icon90PercAnim {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translateX(0);
  }
}
