.sites-apps {
  &__heading {
    width: 100%;
    margin-bottom: 8rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
  }
  &__Square {
    position: absolute;
    z-index: -1;

    &--1 {
      width: 60rem;
      height: 60rem;

      top: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 50%, 50% 100%, 0 100%);

      @media screen and (max-width: 1200px) {
        right: 0;
        left: unset;
      }
      @media screen and (max-width: 900px) {
        top: -50rem;
      }
      @media screen and (max-width: 600px) {
        width: 30rem;
        height: 30rem;
      }
    }
    &--2 {
      width: 20rem;
      height: 20rem;

      left: 0;
      top: 0;
    }
    &--3 {
      width: 30rem;
      height: 30rem;

      right: 0;
      top: 5rem;

      @media screen and (max-width: 1200px) {
        display: none;
      }
      @media screen and (max-width: 900px) {
        display: block;
        width: 60rem;
        height: 60rem;

        left: 0;
        top: -40rem;
        right: unset;
      }
      @media screen and (max-width: 600px) {
        width: 30rem;
        height: 30rem;
      }
    }
    &--4 {
      width: 30rem;
      height: 30rem;

      right: 30rem;
      top: 25rem;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &--5 {
      width: 30rem;
      height: 30rem;

      left: 10rem;
      top: 20rem;

      @media screen and (max-width: 900px) {
        top: 60rem;
        left: unset;
        right: 0;
      }
    }
    &--6 {
      width: 60rem;
      height: 60rem;

      top: 35rem;
      left: 25rem;

      clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);

      @media screen and (max-width: 1200px) {
        width: 30rem;
        height: 30rem;
        clip-path: none;

        top: 70rem;
        right: 0;
        left: unset;
      }
      @media screen and (max-width: 900px) {
        top: 120rem;
      }
    }
  }
  &__icons {
    width: 100%;
    padding: 0 15rem 6rem 15rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1500px) {
      padding: 0 6rem 6rem 6rem;
    }
    @media only screen and (max-width: 1220px) {
      padding: 0;
      padding-bottom: 6rem;
    }
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }

    &--free {
      @media only screen and (max-width: 900px) {
        display: grid;
        justify-items: center;
        align-content: center;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'upkeep upkeep'
          'ga seo';
      }
      @media only screen and (max-width: 500px) {
        display: flex;
      }
    }
  }
  &__mobile-section {
    @media only screen and (max-width: 900px) {
      padding-bottom: 0;
    }
  }
  &__cta-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .heading-3 {
      margin-bottom: 2rem;
    }
    & .cta {
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }

  &__ctaHeading {
    margin-bottom: 2rem;
  }

  & .section .heading-2 {
    margin-bottom: 5rem;
  }
  &__brand-logo {
    & .sites-apps__icons {
      justify-content: center;
      flex-direction: row;

      @media only screen and (max-width: 420px) {
        flex-direction: column;
      }

      &__1 {
        margin-right: 18rem;

        @media only screen and (max-width: 900px) {
          margin-right: 14rem;
        }
        @media only screen and (max-width: 700px) {
          margin-right: 4rem;
        }
        @media only screen and (max-width: 500px) {
          margin-right: 0;
        }
      }
    }
  }
}
