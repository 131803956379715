@import '../../../main';

.paragraph {
  font-size: 2.5rem;
  color: $color-white;

  @media only screen and (max-width: 1800px) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 500px) {
    font-size: 1.8rem;
  }
}
