@import '../../main';

.person-photo {
  position: relative;

  &__round {
    @media only screen and (max-width: 1200px) {
      height: 23rem;
    }
    @media only screen and (max-width: 700px) {
      height: 17rem;
    }
    @media only screen and (max-width: 450px) {
      height: 13rem;
    }
  }
  &__face {
    width: 16rem;
    height: 16rem;
    background-color: black;
    border-radius: 50%;
    border: 5px solid $color-secondary;

    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 55%;

    @media only screen and (max-width: 1200px) {
      height: 10rem;
      width: 10rem;

      left: 53%;
    }
    @media only screen and (max-width: 700px) {
      height: 8rem;
      width: 8rem;
      border: 2px solid $color-secondary;
    }
    @media only screen and (max-width: 450px) {
      height: 6rem;
      width: 6rem;
      border: 1px solid $color-secondary;

      left: 52%;
    }
  }
}
