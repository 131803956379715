@import '../../main';

.footer {
  width: 100%;
  padding: 2.5rem 7rem;
  background-image: linear-gradient(to bottom, rgba($color-primary-light, 0.5), transparent);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  &__left {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 5rem;
      height: 5rem;
      padding: 0;
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  &__center {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__email {
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 900px) {
      width: 100vw;
      padding-left: 4rem;
      margin-bottom: 2rem;

      align-items: flex-start;
    }
  }

  &__centerMobile {
    color: white;

    display: none;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 900px) {
      margin-bottom: 2rem;

      display: flex;
    }

    &__logo {
      width: 9rem;
      height: 9rem;
      margin-bottom: 1rem;
    }
  }

  &__right {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__text {
      margin-bottom: 1rem;

      text-decoration: none;
      color: white;
    }
    &__inp {
      @media only screen and (max-width: 900px) {
        margin-bottom: 1rem;
      }
    }

    @media only screen and (max-width: 900px) {
      width: 100vw;
      padding-right: 4rem;

      align-items: flex-end;

      flex-direction: column-reverse;
    }
  }
}
