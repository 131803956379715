.icon__pwa {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconPwaAppear 1s 1s forwards;
  }

  &__box {
    transform: scale(1, 0.9);
    transform-origin: center bottom;
  }
  &__left-node,
  &__right-node {
    transform-origin: center bottom;
  }

  &:hover {
    .icon__pwa {
      &__box {
        animation: iconPwaBoxAnim 1.5s linear infinite;
      }
      &__left-node,
      &__right-node {
        animation: iconPwaNodeAnim 1.5s linear infinite;
      }
    }
  }
}

@keyframes iconPwaAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconPwaBoxAnim {
  0% {
    transform: scale(1, 0.9);
  }
  25% {
    transform: scale(1.1, 0.8);
  }
  42% {
    transform: scale(0.9, 1);
  }
  60% {
    transform: scale(1.05, 0.85);
  }
}

@keyframes iconPwaNodeAnim {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
