@import '../../../main';

.PortfolioItem {
  width: 70vw;
  height: 45rem;
  margin: 0 auto;
  margin-top: 0rem;
  display: flex;
  position: relative;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    height: max-content;
    margin-top: 3rem;
  }

  &__irframeContainer {
    height: 100%;
    width: 25.125rem;
    margin-right: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media only screen and (max-width: 1000px) {
      margin: 0;
      margin-top: 10rem;
      margin-bottom: 2.5rem;
    }
    @media only screen and (max-width: 400px) {
      transform: scale(0.8);
    }
    &__irframe {
      width: 40rem;
      height: 63rem;
      transform: scale(0.6);
      border-radius: 10px;
      position: absolute;
      z-index: 1;
    }
    &__photo {
      width: 22rem;
      border-radius: 5px;

      object-fit: contain;

      position: absolute;
      z-index: 1;
      &--background {
        position: relative;
        width: 25rem;
        height: 45rem;
        transform: scaleY(4.6) scaleX(1);
        z-index: 0;
      }
    }
    &__background {
      position: relative;
      width: 25rem;
      height: 45rem;
      transform: scaleY(4.6) scaleX(1.1);
      z-index: 0;
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    @media only screen and (max-width: 1000px) {
      justify-content: center;
      align-items: center;
    }
    &__review {
      transform: scale(0.7);
      position: absolute;
      top: -9rem;
      left: -11.5rem;
      @media screen and (max-width: 1200px) {
        transform: scale(1);
        top: -4rem;
      }
      @media only screen and (max-width: 1000px) {
        left: 50%;
        transform: translate(-50%, 0);
      }
      @media only screen and (max-width: 400px) {
        top: -9rem;
      }
    }
    &__heading {
      position: absolute;
      top: 19%;
      left: 19rem;
      transform: translate(0, -50%);
      @media only screen and (max-width: 1000px) {
        width: max-content;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -60rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
      @media only screen and (max-width: 400px) {
        top: -55rem;
      }
      &__link,
      &__link:link,
      &__link:active,
      &__link:visited {
        margin-top: 1rem;
        color: #6a6a6a;
        text-decoration: none;
      }
    }
    &__customer {
      margin-top: 18rem;
      margin-bottom: 1rem;
      color: #31b59e;
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      @media only screen and (max-width: 700px) {
        margin-top: 12rem;
      }
      @media screen and (max-width: 450px) {
        margin-top: 8rem;
      }
      @media screen and (max-width: 400px) {
        margin-top: 3rem;
      }
    }
    &__pargraph {
      height: auto;
      overflow-y: scroll;
    }
  }
}
