.review-round {
  & g {
    transform-origin: center center;
  }
}

@keyframes reviewRoundAnim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
