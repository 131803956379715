@import '../../../main';

.heading3 {
  font-size: 3rem;
  color: $color-secondary;
  text-align: center;

  @media only screen and (max-width: 500px) {
    font-size: 2.5rem;
  }

  &_white {
    color: $color-white;
  }
}
