.section {
  padding: 10rem;
  padding-top: 5rem;

  position: relative;

  @media only screen and (max-width: 700px) {
    padding: 5rem;
  }
  @media only screen and (max-width: 400px) {
    padding: 2rem;
    padding-top: 5rem;
  }
}
