/////
// Colors & shades

// Colors
$color-primary-lighter: #1c2d2d;
$color-primary-light: #252526;
$color-primary: #131616;
$color-primary-background: rgba(0, 0, 0, 0.3);

$color-secondary-light: #eafdfa;
$color-secondary: #05f2c7;
$color-secondary-overlay: rgba($color-secondary, 0.2);
$color-secondary-dark: #1da88f;
$color-secondary-darker: #0b4b40;

$color-action-light: #f5cb57;
$color-action: #ddb032;
$color-action-dark: #c59b25;

$color-red: #f20505;
$color-red-overlay: rgba($color-red, 0.2);
$color-red-dark: #a81d1d;

// Shades
$color-white: white;
$color-grey: #adadad;
$color-black: black;
$color-black-overlay-light: rgba($color-black, 0.5);

/////
// Font
$font-raleway: 'Raleway', sans-serif;
