.icon__security {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconSecurityAppear 1s 0.5s forwards;
  }
  &:hover {
    .icon__security {
      &__box {
        animation: iconSecurityAnim 0.5s linear infinite;
      }
      &__000 {
        & g path {
          stroke-width: 0.3;
          animation: iconSecurity0Anim 0.5s linear infinite;
        }
        &__2 path {
          animation-delay: 0.2s !important;
        }
        &__3 path {
          animation-delay: 0.3s !important;
        }
      }
    }
  }
}

@keyframes iconSecurityAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconSecurityAnim {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0.2rem, 0);
  }
  40% {
    transform: translate(-0.2rem, 0);
  }
  60% {
    transform: translate(0.2rem, 0);
  }
  80% {
    transform: translate(-0.2rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes iconSecurity0Anim {
  0% {
    transform: translate(0, 0);
    stroke-width: 0.3;
  }
  20% {
    transform: translate(0.1rem, 0.1rem);
    stroke-width: 0.3;
  }
  40% {
    transform: translate(-0.1rem, -0.1rem);
    stroke-width: 0.6;
  }
  60% {
    transform: translate(0.1rem, 0.1rem);
    stroke-width: 0.3;
  }
  80% {
    transform: translate(-0.1rem, -0.1rem);
    stroke-width: 0.3;
  }
  100% {
    transform: translate(0, 0);
    stroke-width: 0.3;
  }
}
