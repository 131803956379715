.icon__funnel {
  opacity: 0;
  &:not(.sites-apps__trigger) {
    animation: iconFunnelAppear 1s 1s forwards;
  }
  &:hover {
    .icon__funnel__dollar-1 {
      transform-origin: center center;
      animation: iconFunnelDollar1Anim 2s linear infinite;
    }
    .icon__funnel__dollar-2 {
      transform-origin: center center;
      animation: iconFunnelDollar2Anim 2s linear infinite;
    }
    .icon__funnel__dollar-3 {
      transform-origin: center center;
      animation: iconFunnelDollar3Anim 2s linear infinite;
    }
  }
}

@keyframes iconFunnelAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconFunnelDollar1Anim {
  0% {
    transform: translate(0, 0) rotateY(0deg);
    opacity: 1;
  }
  40% {
    transform: translate(0, 0.5rem) rotateY(90deg);
    opacity: 0;
  }
  60% {
    transform: translate(0, -7rem) rotateY(270deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) rotateY(360deg);
    opacity: 1;
  }
}
@keyframes iconFunnelDollar2Anim {
  0% {
    transform: translate(0, 0) rotateY(0deg);
    opacity: 1;
  }
  40% {
    transform: translate(-1rem, 0.5rem) rotateY(-90deg);
    opacity: 0;
  }
  60% {
    transform: translate(1.5rem, -7rem) rotateY(-270deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) rotateY(-360deg);
    opacity: 1;
  }
}
@keyframes iconFunnelDollar3Anim {
  0% {
    transform: translate(0, 0) rotateY(0deg);
    opacity: 1;
  }
  40% {
    transform: translate(1rem, 0.5rem) rotateY(90deg);
    opacity: 0;
  }
  60% {
    transform: translate(-1.5rem, -7rem) rotateY(270deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) rotateY(360deg);
    opacity: 1;
  }
}
