@import '../../../../main';

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  width: 80%;
  padding: 1.5rem 2rem;
  background-color: $color-primary-lighter;
  border: none;
  outline: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $color-primary-lighter inset !important;
    -webkit-text-fill-color: $color-white;
  }

  letter-spacing: 0.5px;
  font-size: 2rem;
  font-weight: 200;
  font-family: $font-raleway;
  color: $color-white;

  @media only screen and (max-width: 500px) {
    font-size: 1.6rem;
  }

  &_invalid {
    border: 2px solid $color-red-dark;
  }

  &__wrapper {
    width: 100%;
    margin-bottom: 3rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__label {
    margin-bottom: 1rem;

    span {
      color: $color-secondary;
    }
  }

  &__email {
    width: 100%;
  }

  &__note {
    width: 100%;
    resize: none;

    animation: showNote 0.2s ease-out forwards;
  }
}

.openNote {
  width: 100%;
  cursor: pointer;

  color: $color-action;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__plusSign {
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    margin-right: 1rem;
    border: 2px solid $color-action;

    font-size: 2.2rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cta {
  width: 100%;
  margin-bottom: 2rem;

  &_loading {
    cursor: not-allowed;
    transform: translate(0, 0);
  }
}

.gdpr {
  text-decoration: none;
  color: white;
  margin-top: 1rem;
}

@keyframes showNote {
  from {
    height: 0;
  }
  to {
    height: 12rem;
  }
}
