@import '../../main';

.portfolio {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 74rem;

  &__iframe-blocker {
    width: 100vw;
    height: 80vh;

    display: none;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    &--active {
      display: block;
    }
  }
  &__swipeIcon {
    align-self: flex-end;
    margin-right: 5rem;
    @media only screen and (max-width: 500px) {
      align-self: center;
      margin: 0 0 5rem 0;
    }
  }
  &__button {
    margin-top: -1rem;
    margin-bottom: 6rem;
  }
  &__square {
    width: 30rem;
    height: 30rem;

    position: absolute;
    z-index: -1;

    &--1 {
      top: 0;
      left: 10rem;

      @media screen and (max-width: 900px) {
        top: 40rem;
      }
      @media screen and (max-width: 550px) {
        left: 0;
      }
    }
    &--2 {
      bottom: 20rem;
      right: 10rem;

      @media screen and (max-width: 450px) {
        right: 0;
      }
    }
  }
}
.xzcEF {
  margin-bottom: 5rem;
}
button.rec-dot_active {
  background-color: $color-secondary-darker;
  height: 2rem;
  width: 2rem;
  border-color: $color-secondary;
  box-shadow: none;
}
button.rec-dot {
  height: 2rem;
  width: 2rem;
  border-color: $color-secondary-dark;
  box-shadow: 0 0 1px 1px $color-secondary-dark;
}
button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 2px $color-secondary;
}
