@import '../../../main';

.heading-1 {
  margin: 0 5rem;

  font-size: 6rem;
  color: $color-secondary-light;
  text-transform: uppercase;

  @media only screen and (max-width: 1000px) {
    font-size: 5rem;
  }
  @media only screen and (max-width: 800px) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 450px) {
    font-size: 3rem;
  }

  &--secondary {
    color: $color-secondary;
    text-align: center;
  }
}
