.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1150px) {
    width: 20rem;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 10rem;
  }

  &:not(.sites-apps__trigger) {
    & .paragraph {
      animation: iconBoxAppear 1s forwards;

      @keyframes iconBoxAppear {
        0% {
          opacity: 0;
          transform: translateY(2rem);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__svg {
    width: 25rem;
    height: 15rem;
    margin-bottom: 4rem;

    @media only screen and (max-width: 1150px) {
      width: 20rem;
      height: 12rem;
    }
    @media only screen and (max-width: 900px) {
      width: 25rem;
      height: 15rem;

      animation-delay: 0s !important;
    }
  }
  & .paragraph {
    text-align: center;

    opacity: 0;

    @media screen and (max-width: 900px) {
      &.paragraph {
        animation-delay: 0s !important;
      }
    }

    &--0 {
      animation-delay: 0.5s !important;
    }
    &--1 {
      animation-delay: 1s !important;
    }
    &--2 {
      animation-delay: 1.5s !important;
    }
    &--3 {
      animation-delay: 0s !important;
    }
    &--4 {
      animation-delay: 0.5s !important;
    }
    &--5 {
      animation-delay: 1s !important;
    }
    &--6 {
      animation-delay: 0s !important;
    }
    &--7 {
      animation-delay: 0.5s !important;
    }
    &--8 {
      animation-delay: 1s !important;
    }
    &--9 {
      animation-delay: 0s !important;
    }
    &--10 {
      animation-delay: 0s !important;
    }
  }
}
