@import '../../../../main';

.review {
  user-select: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--active {
    transform: translate(5%);

    @media only screen and (max-width: 1200px) {
      transform: translate(8%);
    }
    @media only screen and (max-width: 700px) {
      transform: translate(6%);
    }

    .review {
      &__content {
        width: 70rem;
        opacity: 1;

        transform: translate(-20rem, 2rem);

        @media only screen and (max-width: 1200px) {
          width: 52rem;

          transform: translate(-19rem, 2rem);
        }
        @media only screen and (max-width: 700px) {
          width: 45rem;

          transform: translate(-18rem, 1.5rem);
        }
        @media only screen and (max-width: 450px) {
          width: 32rem;

          transform: translate(-19rem, 1rem);
        }
      }
    }
  }

  &__content {
    height: 26rem;
    width: 0;
    opacity: 0;

    transform: translate(-20rem, 2rem);
    transform-origin: left;

    position: relative;

    transition: all 0.2s ease-out;

    @media only screen and (max-width: 700px) {
      height: 20rem;
    }
    @media only screen and (max-width: 450px) {
      height: 15rem;
    }

    &__block {
      width: 100%;
      height: 83%;
      object-fit: cover;

      position: absolute;
      left: 0;
      top: 0;

      @media only screen and (max-width: 1200px) {
        object-fit: contain;
      }
    }
    &__text {
      width: 100%;
      height: 80%;
      padding: 5rem 5rem 5rem 18rem;
      opacity: 0;
      overflow: hidden;

      color: white;

      display: none;
      align-items: center;
      justify-content: center;

      transition: opacity 1s;

      &--showed {
        opacity: 1;

        display: flex;
      }

      @media only screen and (max-width: 1200px) {
        padding: 5.5rem 10rem 4rem 12rem;
      }
      @media only screen and (max-width: 700px) {
        padding: 4rem 8rem 3rem 8rem;

        font-size: 1.4rem;
      }
      @media only screen and (max-width: 450px) {
        padding: 4rem 7rem 3rem 8rem;

        font-size: 1.2rem;
      }
    }
  }
}
