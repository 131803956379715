.icon__logo {
  overflow: visible;

  &:hover {
    & .icon__logo__text {
      transform-origin: 50% 50%;
      animation: iconLogoText 3s infinite linear;
    }
    & .icon__logo__left {
      animation: iconLogoSides 3s infinite linear;
      transform-origin: 50% 50%;
    }
    & .icon__logo__right {
      animation: iconLogoSides 3s infinite linear;
      transform-origin: 50% 50%;
    }
  }
}

@keyframes iconLogoSides {
  0% {
    transform: scaleX(1);
  }
  10% {
    transform: scaleX(-1);
  }
  20% {
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(-1);
  }
  40% {
    transform: scaleX(1);
  }
  50% {
  }
}

@keyframes iconLogoText {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  5% {
    opacity: 0;
    transform: scale(0);
  }
  40% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
}
