@import '../../../main';

.form {
  &__upper {
    width: 50vw;
    margin-bottom: 1rem;
    padding: 0.5rem;

    background-color: $color-primary-background;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 1px solid $color-secondary;
    border-radius: 8px;

    @media only screen and (max-width: 900px) {
      width: 70vw;
    }
    @media only screen and (max-width: 650px) {
      width: 80vw;
    }
    @media only screen and (max-width: 550px) {
      width: 90vw;
    }

    &--error {
      background-color: $color-red-overlay;
    }

    &__head {
      width: 10%;
      color: white;

      text-align: right;
      margin-left: 2rem;

      @media only screen and (max-width: 950px) {
        margin-left: 1rem;
      }
      @media only screen and (max-width: 500px) {
        margin-right: 4rem;
      }
    }
    &__body {
      width: 70%;

      background-color: transparent;
      color: white;

      border: none;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        box-shadow: 0 0 0px 1000px transparent inset;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      @media only screen and (max-width: 500px) {
        width: 60%;
      }
    }
  }
  &__textarea {
    width: 50vw;
    padding: 1rem;
    margin-bottom: 3rem;

    background-color: $color-primary;
    color: white;

    border: 1px solid $color-secondary;
    border-radius: 8px;

    resize: none;
    outline: none;

    font-family: $font-raleway;

    @media only screen and (max-width: 900px) {
      width: 70vw;
    }
    @media only screen and (max-width: 650px) {
      width: 80vw;
    }
    @media only screen and (max-width: 550px) {
      width: 90vw;
    }

    &--error {
      background-color: $color-red-overlay;
    }
  }
}
