@import '../../main';

.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: $color-black-overlay-light;

  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;

  animation: fadeWrapperIn 0.2s ease-in forwards;
}

.popup {
  width: 80%;
  max-width: 60rem;
  min-width: 30rem;
  border-radius: 1rem;
  padding: 5rem;
  border: 2px solid $color-primary-lighter;
  background-color: $color-primary;

  z-index: 1;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  @media only screen and (max-width: 450px) {
    width: 85%;
    padding: 4rem 2.5rem;
  }
}

@keyframes fadeWrapperIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
