.rocket {
  & > g {
    animation: rocketAnim 1s infinite linear;
  }
  &__fire {
    animation: rocketFireAnim infinite ease-in-out 1.4s;

    &--1 {
      transform-origin: 9% 75%;
    }
    &--2 {
      transform-origin: 22% 91%;
    }
  }
}

@keyframes rocketAnim {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0.3rem, -0.1rem);
  }
  40% {
    transform: translate(-0.1rem, 0.2rem);
  }
  60% {
    transform: translate(0.1rem, 0.3rem);
  }
  80% {
    transform: translate(-0.3rem, -0.1rem);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes rocketFireAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
