.icon__upkeep {
  opacity: 0;

  &:not(.sites-apps__trigger) {
    animation: iconUpkeepAppear 1s forwards;
  }
  &:hover {
    .icon__upkeep {
      &__hand {
        animation: iconUpkeepHandAnim 1s infinite;
      }
      &__tick path {
        animation: iconUpkeepTickAnim 1s infinite;
      }
    }
  }
}

@keyframes iconUpkeepAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconUpkeepHandAnim {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  40% {
    transform: translate(10%, -10%) rotate(0);
  }
  50% {
    transform: translate(10%, -10%) rotate(5deg);
  }
  60% {
    transform: translate(10%, -10%) rotate(0);
  }
  80% {
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
@keyframes iconUpkeepTickAnim {
  0% {
    stroke-width: 0.7;
  }
  52% {
    stroke-width: 0.7;
  }
  53% {
    stroke-width: 1.2;
  }
  100% {
    stroke-width: 1.2;
  }
}
