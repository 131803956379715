@import './main';

.app {
  min-height: 100vh;
  background-color: $color-primary;

  animation: appear 1s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1s;
  }
}
