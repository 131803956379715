.MainText {
  width: 100%;
  height: 100vh;

  position: absolute;
  top: -11rem;

  transform-origin: 50% 50%;

  transition: all 5s;

  @media screen and (max-width: 450px) {
    display: none;
  }
}
