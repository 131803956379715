@import '../../main';

.navigation {
  width: 100%;
  padding: 2rem 6rem;
  background-color: $color-primary;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 1;
  opacity: 0.7;

  @media screen and (max-width: 950px) {
    height: 9rem;

    opacity: 1;
  }
  @media screen and (max-width: 900px) {
    padding: 2rem 3rem;
  }
  @media screen and (max-width: 650px) {
    height: 5rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem 3rem;
  }
  @media screen and (max-width: 350px) {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: 700px) {
    padding: 4rem 5rem;
  }
  @media only screen and (max-width: 400px) {
    padding: 4rem 2rem;
  }

  &__amniom {
    color: $color-grey;
    font-size: 2rem;

    display: flex;
    align-items: center;

    z-index: 1000;

    @media screen and (max-width: 900px) {
      margin-right: 2rem;

      opacity: 0.7;
    }
    @media screen and (max-width: 650px) {
      font-size: 1.6rem;
    }

    &__navlink {
      color: inherit;

      display: flex;
      align-items: center;
    }

    &__img {
      height: 5rem;
      width: 5rem;
      margin-right: 1rem;

      @media screen and (max-width: 1000px) {
        height: 4rem;
        width: 4rem;
      }
      @media screen and (max-width: 650px) {
        height: 3rem;
        width: 3rem;
      }
    }
  }
  &__navlink {
    padding: 0 2rem;

    color: $color-grey;
    font-size: 2rem;

    &--active {
      color: $color-secondary-dark;
    }

    &--highlight {
      padding: 0.7rem 2rem;
      border: 2px solid $color-action;

      &:hover {
        color: $color-action;
      }

      &--active {
        color: $color-secondary-dark;
        border: 2px solid $color-secondary-dark;

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }
  }
  &__routes {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  &__mobile {
    display: none;

    @media only screen and (max-width: 900px) {
      display: block;
    }

    &__menu {
      height: 5rem;
      width: 5rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      position: relative;
      z-index: 1000;

      &--open {
        .navigation__mobile__menu__hamburger {
          &::after {
            top: -1.5rem;
          }
          &::before {
            bottom: -1.5rem;
          }
        }
      }

      &__hamburger {
        height: 5px;
        width: 5rem;
        background-color: $color-secondary-dark;

        position: relative;
        z-index: 1000;

        @media screen and (max-width: 900px) {
          opacity: 0.7;
        }
        @media screen and (max-width: 650px) {
          width: 4rem;
        }

        &::after,
        &::before {
          content: '';
          width: 5rem;
          height: 5px;
          background-color: $color-secondary-dark;

          box-sizing: border-box;

          display: block;

          position: absolute;

          transition: top 0.2s, bottom 0.2s;

          @media screen and (max-width: 650px) {
            width: 4rem;
          }
        }

        &::after {
          top: -1.2rem;
        }
        &::before {
          bottom: -1.2rem;
        }
      }
    }
    &__routes {
      width: 100vw;
      height: 100vh;
      padding-bottom: 5rem;
      background-color: $color-primary;
      user-select: none;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      z-index: 100;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden;

      @keyframes mobileRoutesBefore {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-primary;

        display: block;

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        animation: mobileRoutesBefore 1s 0s forwards;
      }

      .navigation {
        &__navlink {
          margin: 1rem 0;
        }
        &__border {
          height: 1px;
          background-color: $color-secondary;

          &--short {
            width: 10rem;
          }
          &--long {
            width: 15rem;
          }
        }
      }

      &__canvas {
        width: 100%;
        height: 100vh;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
      }
    }
  }
}
