@import 'main.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
  font-size: 62.5%;
  font-family: $font-raleway;
  overflow-x: hidden;
  cursor: url('./assets/img/cursor.png'), auto;
}

body {
  font-size: 1.6rem;
  box-sizing: border-box;
  overflow: hidden;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  background-color: $color-primary;
  border-left: 1px solid $color-secondary-darker;
}
::-webkit-scrollbar-thumb {
  background-image: repeating-linear-gradient(
    -45deg,
    $color-primary,
    $color-primary 6px,
    $color-secondary 6px,
    $color-secondary 9px
  );
  box-shadow: 2px 1px 5px 1px $color-secondary-darker inset;

  &:hover {
    box-shadow: 2px 1px 10px 1px $color-secondary-dark inset;
  }
}

::selection {
  background-color: $color-secondary-darker;
  color: $color-secondary;
}
