.calculation {
  width: 100%;
  min-height: 65rem;
  height: 100vh;
  padding-top: 15rem;

  @media only screen and (max-height: 850px) {
    padding-top: 10rem;
  }
  @media only screen and (max-height: 750px) {
    padding-top: 5rem;
  }
}
