@import '../../../../main';

.form-modal {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 500000;
  position: fixed;
  top: 0;
  left: 0;

  &--success {
    background-color: $color-secondary-overlay;

    & .form-modal {
      &__content {
        border: 1px solid $color-secondary;
        background-color: $color-secondary-dark;
      }
    }
  }
  &--error {
    background-color: $color-red-overlay;

    & .form-modal {
      &__content {
        border: 1px solid $color-red;
        background-color: $color-red-dark;
      }
    }
  }

  &__content {
    padding: 3rem 5rem;
    border-radius: 0.5rem;
    margin-top: -10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__heading {
    margin-bottom: 1rem;

    font-size: 2rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  &__text {
    font-size: 1.6rem;
    color: white;

    & a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
