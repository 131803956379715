@import '../../../main';

.review-box {
  width: 100%;
  height: 100rem;
  overflow: hidden;
  user-select: none;

  position: relative;

  @media only screen and (max-width: 1200px) {
    height: 65rem;
  }
  @media only screen and (max-width: 700px) {
    height: 50rem;
  }
  @media only screen and (max-width: 450px) {
    height: 40rem;
  }

  &__reviews {
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateX(-50%);
    position: absolute;
    left: 50%;
  }
  &__canvas {
    position: absolute;
    width: 100%;
    height: 100%;

    opacity: 0.1;
  }
  &__gradient {
    width: 100%;
    height: 10%;

    z-index: 1;
    position: absolute;
    left: 0;

    display: none;

    &--top {
      background-image: linear-gradient(to bottom, $color-primary 0% 50%, transparent);

      top: 0;
    }
    &--bot {
      background-image: linear-gradient(to bottom, transparent, $color-primary 50% 100%);

      bottom: 0;
    }
  }
  &__arrows {
    display: flex;
    flex-direction: column;

    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 5rem;

    @media only screen and (max-width: 750px) {
      right: 3rem;
    }
  }
  &__arrow {
    width: 4rem;
    height: 4rem;

    &--prev {
      transform: rotate(-90deg);
      margin-bottom: 2rem;

      @media only screen and (max-width: 650px) {
        margin-bottom: 20rem;
      }
    }
    &--next {
      transform: rotate(90deg);
    }
  }
}
