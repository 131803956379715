@import '../../../main';

.heading-2 {
  font-size: 4rem;
  color: $color-secondary;

  @media only screen and (max-width: 600px) {
    font-size: 3.5rem;
  }
  @media only screen and (max-width: 500px) {
    font-size: 3rem;
  }
}
