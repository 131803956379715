.icon__sites {
  opacity: 0;

  &--1 {
    &:not(.sites-apps__trigger) {
      animation: iconSitesAppear 1s 0.5s forwards;
    }
  }
  &--2 {
    &:not(.sites-apps__trigger) {
      animation: iconSitesAppear 1s 1.5s forwards;
    }
  }

  &:hover {
    .icon__sites {
      &__person {
        transform-origin: center bottom;
        animation: iconSitesPersonAnim 1.5s ease-in-out infinite;
      }
      &__cloud {
        transform-origin: center bottom;
        animation: iconSitesCloudAnim 1.5s ease-in-out infinite;
      }
    }
  }
}

@keyframes iconSitesAppear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconSitesPersonAnim {
  0% {
    transform: scale(1) rotate(0);
  }
  20% {
    transform: scale(1) rotate(15deg);
  }
  40% {
    transform: scale(1) rotate(-15deg);
  }
  60% {
    transform: scale(1) rotate(0deg);
  }
  80% {
    transform: scale(0) rotate(0);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
@keyframes iconSitesCloudAnim {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
