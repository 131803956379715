@import '../../scss//variables';

.NotFound {
  padding-top: 10rem;
  padding-bottom: 30rem;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $color-secondary;
  color: transparent;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__square {
    width: 30rem;
    height: 30rem;

    position: absolute;

    @media screen and (max-width: 1200px) {
      width: 20rem;
      height: 20rem;
    }

    &_1 {
      left: 0;
      top: 30rem;

      @media screen and (max-width: 500px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &_2 {
      top: 0;
      right: 20rem;

      @media screen and (max-width: 900px) {
        right: 0;
        top: 10rem;
      }
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
  &__404 {
    font-size: 16rem;

    position: relative;

    @media screen and (max-width: 1200px) {
      font-size: 11rem;
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 2rem;

      font-size: 8rem;
    }
    @media screen and (max-width: 700px) {
      font-size: 6rem;
    }

    &__2 {
      position: absolute;
      left: 5px;
      top: 5px;

      @media screen and (max-width: 700px) {
        left: 3px;
        top: 3px;
      }
    }
  }
  &__sorry {
    font-size: 8rem;
    text-transform: uppercase;

    position: relative;

    @media screen and (max-width: 1200px) {
      font-size: 6rem;
    }
    @media screen and (max-width: 900px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 700px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 600px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 420px) {
      font-size: 2rem;
    }

    &__2 {
      width: 100%;

      position: absolute;
      left: 5px;
      top: 5px;

      @media screen and (max-width: 700px) {
        left: 3px;
        top: 3px;
      }
      @media screen and (max-width: 420px) {
        display: none;
      }
    }
  }
}
